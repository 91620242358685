import { AfterContentInit, Directive, ElementRef, Injectable, Input, NgModule, Renderer2 } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { AnalyticsTrack } from '@nx-bundesliga/models';

@Injectable()
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[analyticsOn]' })
export class AnalyticsOnDirective implements AfterContentInit {
	@Input() analyticsOn: string;
	@Input() analyticsAction: string;
	@Input() analyticsCategory: string;
	@Input() analyticsLabel: string;
	@Input() analyticsValue: string;
	@Input() analyticsProperties: any = {};
	@Input() analyticsAmplitudeEventName: string;

	constructor(private elRef: ElementRef, private renderer: Renderer2, private analyticsService: AnalyticsService) {}

	// eslint-disable-next-line @angular-eslint/contextual-lifecycle
	ngAfterContentInit() {
		this.renderer.listen(this.elRef.nativeElement, this.analyticsOn || 'click', (event: Event) => this.eventTrack(event));
	}

	eventTrack(event: Event) {
		const action = this.analyticsAction; // || this.inferEventName();
		const properties: AnalyticsTrack = {
			...this.analyticsProperties,
			eventType: event.type,
			action: this.analyticsAction
		};

		if (this.analyticsCategory) {
			properties.category = this.analyticsCategory;
		}
		if (this.analyticsLabel) {
			properties.label = this.analyticsLabel;
		}
		if (this.analyticsValue) {
			properties.value = this.analyticsValue;
		}
		if (this.analyticsAmplitudeEventName) {
			properties.amplitudeEventName = this.analyticsAmplitudeEventName;
		}
		this.analyticsService.eventTrack.next(properties);
	}
}

@NgModule({
	declarations: [AnalyticsOnDirective],
	exports: [AnalyticsOnDirective]
})
export class AnalyticsOnModule {}
