import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfileAccount, ProfileState } from '@nx-bundesliga/models';

export const _getWorkingProfile = (state: ProfileAccount) => state;
export const _getAuthorized = (state: ProfileAccount) => state.authorized;

const selectProfileAppState = createFeatureSelector<ProfileState>('profile');
const selectProfileState = createSelector(selectProfileAppState, (cur: ProfileState) => cur.profile);
// const selectProfileUser = createSelector(selectProfileAppState, (cur: ProfileState) => cur.profile.user);
export const getWorkingProfile = createSelector(selectProfileState, _getWorkingProfile);
// export const getWorkingUser = createSelector(selectProfileUser, _getWorkingProfile);
export const getAuthenticated = createSelector(selectProfileState, _getAuthorized);
