import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AsyncSubject, Observable, of, zip } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';
import { AnalyticsPageTrack } from '@nx-bundesliga/models';

/**
 * Track Route changes for applications using Angular's
 * default router
 *
 * @link https://angular.io/api/router/Router
 */

@Injectable()
export class AnalyticsRouterTracking {
	private clearQueryParams = true;
	private clearHash = true;
	private clearIds = false;
	private idsRegExp = /^\d+$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
	private customPageTrackData$: AsyncSubject<any> = new AsyncSubject<any>();

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private analyticsService: AnalyticsService) {}

	public init() {
		this.trackLocation().subscribe((e: AnalyticsPageTrack) => {
			this.trackUrlChange(e.path, e.custom);
		});
	}

	trackLocation(): Observable<AnalyticsPageTrack> {
		const routerNavigationEnd$: Observable<Event | RouterEvent> = this.router.events.pipe(filter((e: Event | RouterEvent) => e instanceof NavigationEnd));

		return zip(
			routerNavigationEnd$.pipe(map((e: NavigationEnd) => e.urlAfterRedirects)),

			routerNavigationEnd$.pipe(
				map((e: NavigationEnd) => this.activatedRoute),
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === 'primary'),
				mergeMap((route) => route.data),
				switchMap((routeData) => {
					if (routeData && routeData['tracking'] && routeData['tracking']['waitForData']) {
						return this.customPageTrackData$.pipe(
							map((customData) => {
								if (customData === null) {
									return { 'event': '404pageEvent' };
								}
								return { ...customData, ...routeData['tracking']['custom'] };
							})
						);
					} else {
						if (routeData && routeData['tracking'] && routeData['tracking']['custom']) {
							return of(routeData['tracking']['custom']);
						} else {
							return of({});
						}
					}
				}),
				tap(() => {
					this.customPageTrackData$ = new AsyncSubject<any>();
				})
			),

			routerNavigationEnd$.pipe(
				map((e: NavigationEnd) => this.activatedRoute),
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === 'primary'),
				mergeMap((route) => route.data),
				mergeMap((routeData) => {
					if (routeData && routeData['tracking'] && routeData['tracking']['doNotTrack']) {
						return of(!!routeData['tracking']['doNotTrack']);
					} else {
						return of(false);
					}
				})
			)
		).pipe(
			filter(([pagePath, pageTrackData, doNotTrack]) => doNotTrack !== true),
			map(([pagePath, pageTrackData, doNotTrack]) => ({
				'path': pagePath,
				'custom': pageTrackData
			}))
		);
	}

	protected trackUrlChange(url: string, custom: any = {}) {
		const path = custom['path'] || url;
		delete custom.path;
		// const clearedUrl = this.clearUrl(url);
		// const path = this.location.prepareExternalUrl(clearedUrl);
		this.analyticsService.pageTrack.next({ path: path, custom: custom });
	}

	/**
	 * Removes id's from tracked route.
	 *  EX: `/project/12981/feature` becomes `/project/feature`
	 *
	 * @param url current page path
	 */
	protected clearUrl(url: string): string {
		if (this.clearIds || this.clearQueryParams || this.clearHash) {
			return url
				.split('/')
				.map((part) => (this.clearQueryParams ? part.split('?')[0] : part))
				.map((part) => (this.clearHash ? part.split('#')[0] : part))
				.filter((part) => !this.clearIds || !part.match(this.idsRegExp))
				.join('/');
		}
		return url;
	}

	public setCustomPageTrackData(data: any) {
		if (data) {
			// non-null data will be emitted
			this.customPageTrackData$.next(data);
		} else {
			// null or empty data will be emitted as empty object instead
			this.customPageTrackData$.next(null);
		}
		this.customPageTrackData$.complete();
	}
}
