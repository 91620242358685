// angular
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalyticsRouterTracking } from './analytics-router-tracking.service';
import { AnalyticsScriptsLoaderService } from './analytics-scripts-loader.service';
// module
import { AnalyticsService } from './analytics.service';
import { AnalyticsOnDirective, AnalyticsOnModule } from './analyticsOn.directive';
import { AnalyticsAmplitudeService } from './analyticsAmplitude.service';

export { AnalyticsService };
export { Analytics } from './analytics.service';
export { AnalyticsScriptsLoaderService };
export { AnalyticsRouterTracking };

@NgModule({
	imports: [RouterModule, AnalyticsOnModule],
	exports: [AnalyticsOnModule],
	declarations: [],
	providers: []
})
export class AnalyticsModule {
	static forRoot(): ModuleWithProviders<AnalyticsModule> {
		return {
			ngModule: AnalyticsModule,
			providers: [AnalyticsService, AnalyticsRouterTracking, AnalyticsOnDirective, AnalyticsScriptsLoaderService, AnalyticsAmplitudeService]
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: AnalyticsModule) {
		if (parentModule) {
			// TODO: Investigate if forRoot DependencyInjection Encapsulation is needed here.
			// throw new Error('AnalyticsModule already loaded. Import in root module only.');
		}
	}
}
