// angular
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
// libs
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsExceptionTrack, AnalyticsPageTrack, AnalyticsTrack, AnalyticsWebviewTrack, IAnalytics, ProfileAccount } from '@nx-bundesliga/models';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { getWorkingProfile } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { AnalyticsAmplitudeService } from './analyticsAmplitude.service';
import { CookieConsentService } from '@nx-bundesliga/bundesliga-com/services/cookie-consent';

@Injectable()
export class AnalyticsService implements IAnalytics {
	private dataLayer: any;
	private userId = null;
	private isBrowser: boolean;

	private user$: Observable<ProfileAccount>;

	public enableAmplitude = false;
	public enableMatomo = true;

	pageTrack = new ReplaySubject<Partial<AnalyticsPageTrack>>(10);
	eventTrack = new ReplaySubject<Partial<AnalyticsTrack>>(10);
	exceptionTrack = new ReplaySubject<any>(10);
	username = new ReplaySubject<{ userId: string | number } | string>(10);

	constructor(
		@Inject(WINDOW) private window: Window,
		@Inject(PLATFORM_ID) platformId: Object,
		private readonly config: ConfigService,
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private pstore: Store<ProfileAccount>,
		private cookieConsentService: CookieConsentService,
		private amplitudeService: AnalyticsAmplitudeService
	) {
		this.isBrowser = isPlatformBrowser(platformId);
		const buildStage = this.config.getSettings('build', 'prod');
		if (buildStage === 'dev' || buildStage === 'localhost') {
			this.enableAmplitude = true; // enable amplitude only on dev + localhost
		}

		this.dataLayer = (<any>this.window).dataLayer;
		// The dataLayer needs to be initialized, use windowservice to avoid errors on server
		if (typeof (<any>this.window).dataLayer !== 'undefined' && (<any>this.window).dataLayer) {
			this.dataLayer = (<any>this.window).dataLayer || [];
		}

		this.pageTrack.pipe(filter(() => this.isBrowser)).subscribe((x: AnalyticsPageTrack) => this.trackPage(x.path, x.custom));
		this.eventTrack.pipe(filter(() => this.isBrowser)).subscribe((x: AnalyticsTrack) => this.track(x));
		this.exceptionTrack.pipe(filter(() => this.isBrowser)).subscribe((x: any) => this.trackException(x));
		this.username.pipe(filter(() => this.isBrowser)).subscribe((x: string) => this.setUsername(x));
		this.user$ = this.pstore.pipe(
			select(getWorkingProfile),
			filter((user: ProfileAccount) => !user.pending)
		);

		this.cookieConsentService.consents$.subscribe((consents) => {
			if (consents && consents.C0004 === true) {
				this.enableAmplitude = true; // Only send tracking if C0004 is true
			} else {
				this.enableAmplitude = false;
			}
		});
	}

	trackPage(path: string, custom = {}) {
		const mobileParams = { screenName: path, screenType: 'Webview', service: 'Bundesliga', ...custom };
		if ((<any>this.window).AnalyticsWebInterface) {
			// Call Android interface
			(<any>this.window).AnalyticsWebInterface.logEvent('screenview', JSON.stringify(mobileParams));
		} else if ((<any>this.window).webkit && (<any>this.window).webkit.messageHandlers && (<any>this.window).webkit.messageHandlers.firebase) {
			// Call iOS interface
			const message = {
				command: 'logEvent',
				name: 'screenview',
				parameters: mobileParams
			};
			(<any>this.window).webkit.messageHandlers.firebase.postMessage(message);
		} else {
			// No Android or iOS interface found. Proceed with desktop tracking

			if (this.enableAmplitude) {
				this.user$.subscribe((profile: ProfileAccount) => {
					this.amplitudeService.sendPageviewToGTMAmplitude(path, custom, profile);
				});
			}

			if (this.enableMatomo) {
				this.sendPageviewToGTMMatomo(path, custom);
			}
		}
	}

	private sendPageviewToGTMMatomo(path: string, custom: {}) {
		if (typeof this.dataLayer !== 'undefined' && this.dataLayer) {
			const pageViewEvent = {
				'event': 'bundesligaEvent',
				'virtPath': path,
				...custom
			};
			this.dataLayer.push(pageViewEvent);
		}
	}

	/**
	 * Send interactions to the dataLayer, i.e. for event tracking in Google Analytics
	 *
	 * @param properties: AnalyticsTrack associated with the event
	 */
	track(properties: AnalyticsTrack) {
		//  @param {string} properties.category
		//  @param {string} [properties.label]
		//  @param {number} [properties.value]
		//  @param {boolean} [properties.noninteraction]
		// Set a default GTM category
		if ((<any>this.window).AnalyticsWebInterface) {
			// Call Android interface
			(<any>this.window).AnalyticsWebInterface.logEvent(properties.action, JSON.stringify(properties));
		} else if ((<any>this.window).webkit && (<any>this.window).webkit.messageHandlers && (<any>this.window).webkit.messageHandlers.firebase) {
			// Call iOS interface
			const message = {
				command: 'logEvent',
				name: properties.action,
				parameters: properties
			};
			(<any>this.window).webkit.messageHandlers.firebase.postMessage(message);
		} else {
			if (properties.abTestVersion) {
				if (!properties.custom) {
					properties.custom = {};
				}
				properties.custom.abTestVersion = properties.abTestVersion;
			}

			// No Android or iOS interface found. Proceed with desktop tracking
			if (this.enableMatomo) {
				this.sendEventToGTMMatomo(properties);
			}
			if (this.enableAmplitude) {
				this.amplitudeService.sendEventToGTMAmplitude(properties);
			}
		}
	}

	private sendEventToGTMMatomo(properties: AnalyticsTrack): void {
		if (typeof this.dataLayer !== 'undefined' && this.dataLayer) {
			const dataLayerEvent = {
				event: properties.event || 'interaction',
				category: properties.category || 'Event',
				action: properties.action,
				label: properties.label,
				value: properties.value,
				interactionType: properties.noninteraction,
				userId: this.userId ? this.userId : '',
				...properties.custom
			};
			this.dataLayer.push(dataLayerEvent);
		}
	}

	addWebviewEventToDatalayer(properties: AnalyticsWebviewTrack) {
		if (typeof this.dataLayer !== 'undefined' && this.dataLayer) {
			const webviewTrackingData = {
				product: this.mapProductNameForTracking(properties.product),
				user_id: this.userId ? this.userId : null,
				event: properties.event,
				screen_name: properties.screen_name,
				event_category: properties.event_category,
				operating_system: this.isAppleDevice() ? 'ios' : this.isAndroidDevice() ? 'android' : ''
			};

			const appInstallationId = this.route.snapshot.queryParams['appInstallationId'];
			const appVisitorId = this.route.snapshot.queryParams['appVisitorId'];

			if (appInstallationId && appInstallationId !== '') {
				webviewTrackingData['appInstallationId'] = appInstallationId;
			}
			if (appVisitorId && appVisitorId !== '') {
				webviewTrackingData['appVisitorId'] = appVisitorId;
			}
			this.dataLayer.push(webviewTrackingData);
		}
	}

	private isAppleDevice(): boolean {
		if (this.isBrowser) {
			return !!navigator.userAgent.match(/(iPod|iPhone|iPad)/);
		} else {
			return false;
		}
	}

	private isAndroidDevice(): boolean {
		if (this.isBrowser) {
			return !!navigator.userAgent.match(/(Android)/);
		} else {
			return false;
		}
	}

	public trackWebviewScreenEvent(screen_name, event, category, product = 'web') {
		if (product === 'web') {
			const redirectUri = this.route.snapshot.queryParams.hasOwnProperty('redirectUri') ? this.route.snapshot.queryParams.redirectUri : 'web';
			product = redirectUri.includes('fantasy') ? 'fantasy' : redirectUri.includes('bundesligapp') ? 'bundesliga-app' : 'web';
		}

		if (screen_name && event && category) {
			this.addWebviewEventToDatalayer({
				screen_name: screen_name,
				event: event,
				event_category: category,
				product: product
			});
		}
	}

	/**
	 * maps web product name like web, fantasy, bundesliga-app to Tracking product names.
	 *
	 * @param string: productName (web, fantasy, bundesliga-app)
	 * returns string
	 */
	private mapProductNameForTracking(productName: string): string {
		if (!productName || productName === '' || productName.toLowerCase() === 'web') {
			return 'Web BL';
		}
		if (productName.toLowerCase() === 'bundesliga-app') {
			return 'App BL';
		}
		if (productName.toLowerCase() === 'fantasy') {
			return 'Fantasy App';
		}
		return 'Web BL';
	}

	/**
	 * Exception Track Event in GTM
	 *
	 */
	trackException(properties: AnalyticsExceptionTrack) {
		//  @param {Object} properties
		//  @param {string} properties.appId
		//  @param {string} properties.appName
		//  @param {string} properties.appVersion
		//  @param {string} [properties.description]
		//  @param {boolean} [properties.fatal]
		if (!properties || !properties.appId || !properties.appName || !properties.appVersion) {
			console.log('Must be set: appId, appName and appVersion.');
			return;
		}

		if (properties.fatal === undefined) {
			console.log('No "fatal" provided, sending with fatal=true');
			properties.fatal = true;
		}

		const exDescription = properties.event ? properties.event.stack : properties.description;

		this.track({
			'action': `Exception thrown for ${properties.appName} <${properties.appId}@${properties.appVersion}>`,
			'category': 'Exception',
			'label': exDescription
		});
	}

	/**
	 * Set userId for use with Universal Analytics User ID feature
	 *
	 * @param userId used to identify user cross-device in Google Analytics
	 */
	setUsername(userId: string) {
		this.userId = userId;
	}
}

export class Analytics implements IAnalytics {
	constructor(@Inject(AnalyticsService) public analytics: AnalyticsService) {}

	track(event: AnalyticsTrack): void {
		this.analytics.track(event);
	}
}
